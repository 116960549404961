import React, { useCallback, useEffect, useState } from "react"
import * as styles from "./detail.module.css"
import fullView from "images/smallSliceIcon.png"
import { navigate } from "gatsby"
import Slider from "components/tools/slide/Slide.js"
import InputTitle from "components/tools/InputTitle.js"
import Story from "components/story/story"
import dayjs from "dayjs"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
  getHiddenStoryListAsync,
  getRelatedListAsync,
  getStoryAsync,
} from "store/modules/hiddenReducer"
// import SlidePagination from "components/tools/slide/slidePagination.js"
import { getThumb, permission } from "utils"
const Detail = props => {
  console.log(20, props.hidden_no)
  const [title, setTitle] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    setTitle(props.title)
  }, [])

  const getStory = useCallback(
    number => dispatch(getStoryAsync(number)),
    [dispatch]
  )

  const getRelatedSList = useCallback(
    (page, take, order, number) =>
      dispatch(getRelatedListAsync(page, take, order, number)),
    [dispatch]
  )

  const { story } = useSelector(
    ({ hiddenReducer: { data } }) => data,
    shallowEqual
  )
  useEffect(() => {
    ;(async () => {
      console.log(props.hidden_no)
      getStory(props.story_no)
      // getStoryList(1, 0, 1, props.hidden_no)
      getRelatedSList(1, 0, 1, props.hidden_no)
    })()
  }, [props.story_no, props.hidden_no])
  console.log(55, story)
  const pageList = story.related
  const readStory = () => {
    const url = `/peachPage?story_no=${props.story_no}`
    navigate(url, {
      state: {
        isHidden: true,
      },
    })
  }

  console.log(pageList)

  const storySlice = pageList?.filter(obj => obj.story_no < props.story_no)

  return (
    <div className={styles.container}>
      <div className={styles.con}>
        <div className={styles.contents}>
          <img
            className={styles.img}
            src={getThumb(story.detail.thumb_title_uuid)}
            alt={props.src}
          />
          <div className={styles.title}>{story.detail.title}</div>
          <div className={styles.read} onClick={readStory}>
            스토리 읽기
          </div>
          <div className={styles.register}>
            {dayjs
              .unix(story.detail.registerTime)
              .locale("ko")
              .format("YYYY년 M월 D일 발행")}
          </div>
        </div>
      </div>
      <div className={styles.con}>
        <div>
          <div className={styles.story_intro}>스토리 소개</div>
          <div className={styles.story_summary}>
            {story.detail.introText?.length < 500
              ? story.detail.introText
              : story.detail.introText?.slice(0, 500)}
          </div>
        </div>
      </div>
      <div className={styles.con}>
        <div style={{ width: "800px" }}>
          <div className={styles.slideCon}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <InputTitle
                name={"연관 스토리"}
                titleSize={22}
                style={{
                  margin: "50px 10px 29px 0px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  fontSize: "16px",
                  placeItems: "center",
                  minWidth: "fit-content",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/pro/${title}`)
                }}
              >
                <div>전체 보기</div>
                <img
                  src={fullView}
                  style={{ width: "17px", height: "17px", marginLeft: "5px" }}
                />
              </div>
            </div>
            <Slider name={"associated"} length={storySlice?.length}>
              {storySlice?.map(story => {
                console.log(130, story)
                return (
                  <Story
                    registerTime={story.publishTime}
                    key={story.story_no}
                    onClick={() => navigate(`/pro/${title}/${story.story_no}`)}
                    likeImg={true}
                    story_no={story.story_no}
                    title={story.title}
                    // introText={story.introText}
                    thumb_title_uuid={story.thumb_title_uuid}
                  />
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Detail
