// extracted by mini-css-extract-plugin
export var container = "detail-module--container--TbC2n";
export var con = "detail-module--con--2EdJm";
export var header = "detail-module--header--3dyTK";
export var logo_box = "detail-module--logo_box--3MFrK";
export var logo = "detail-module--logo--3O1p-";
export var contents = "detail-module--contents--1n8B8";
export var img = "detail-module--img--1D_fl";
export var title = "detail-module--title--mLBei";
export var read = "detail-module--read--28wGx";
export var register = "detail-module--register--1PYgR";
export var story_intro = "detail-module--story_intro--kOlQP";
export var story_summary = "detail-module--story_summary--2TC8t";
export var related = "detail-module--related---zLcs";
export var related_story = "detail-module--related_story--2lJmh";
export var show_all = "detail-module--show_all--3H4z5";
export var related_title = "detail-module--related_title--1CCrT";
export var related_card = "detail-module--related_card--3FFvz";
export var card_box = "detail-module--card_box--1uCE3";
export var slideCon = "detail-module--slideCon--33lQP";